<template>
  <div class="add main-block">
    <div class="add__box main-block__head">
      <h1 class="add__box-title main-block__title">Характеристики</h1>
    </div>
    <ul class="add__items">
      <li class="add__item flex">
        <p class="add__key">Объем, ДхШхВ *</p>
        <input type="number" step="0.05" class="add__value" placeholder="м3" v-model="volume" @input="onChange('volume', volume)" required />
      </li>
      <li class="add__item flex">
        <p class="add__key">Вес, кг *</p>
        <input type="text" class="add__value" placeholder="кг" v-model="weight" @input="onChange('weight', weight)" required />
      </li>
      <li class="add__item flex">
        <p class="add__key">Название груза *</p>
        <input type="text" class="add__value" placeholder="Введите название груза" v-model="product_name" @input="onChange('product_name', product_name)" required />
      </li>
      <!-- <li class="add__item flex">
        <p class="add__key">Вид груза: стекло, жидкость, другое *</p>
        <b-form-select
          v-model="selected"
          class="add__value main__select"
          :options="options"
          required
        />
        <img class="main__select-icon" src="@/assets/png/select-icon.png" alt="" />
      </li> -->
      <!-- <li class="add__item flex">
        <p class="add__key">Температурный режим при транспортировке</p>
        <input type="text" class="add__value" placeholder="кг" />
      </li> -->
    </ul>
  </div>
</template>

<script>
export default {
  data() {
    return {
      selected: null,
      options: [
        { value: null, text: "стекло" },
        { value: "a", text: "жидкость" },
        { value: "a", text: "другое" },
      ],
      volume: 0.1,
      weight: 0,
      product_name: ''
    };
  },
  methods: {
    async onChange(key, param) {
      this.$emit("onInputHandler", {
        key,
        param,
      });
    }
  }
};
</script>

<style scoped>
.add {
  min-height: auto;
  padding-bottom: 30px;
}

.add__items {
  font-size: 12px;
  line-height: 14px;
  color: #707070;
  padding: 3.5px 0 0 0;
}

.add__item {
  padding-top: 11.5px;
  height: 27.5px;
  position: relative;
}

.add__item:not(:last-child) {
  padding-bottom: 11.5px;
  height: 39px;
}

.main__select-icon {
  right: 8px;
  top: 53%;
  width: 7px;
}

.add__key {
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  text-align: left;
  color: #000000;
}

.add__value {
  width: 48%;
  padding-bottom: 3px;
  padding-left: 3px;
  border: none;
  border-radius: 0;
  border-bottom: 1px solid #acacac;
  font-size: 12px;
  line-height: 14px;
  color: #000000;
  padding-right: 10px;
}
</style>
